import Vue from 'vue'
import VueRouter from 'vue-router'
// import useJwt from '@/auth/jwt/useJwt'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'Dashboard',
            component: () => import('@/views/pages/Dashboard/Dashboard.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Dashboard',
                breadcrumb: [
                    {
                        text: 'Dashboard',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/clientes',
            name: 'Clientes',
            component: () => import('@/views/pages/Customers/Clientes.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Clientes',
                breadcrumb: [
                    {
                        text: 'Clientes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/visitas',
            name: 'Visitas',
            component: () => import('@/views/pages/Visitas/VisitsList.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Visitas',
                breadcrumb: [
                    {
                        text: 'Visitas',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/visitas/detalles?id=:idCustomer',
            name: 'Detalles Cliente',
            component: () => import('@/views/pages/Customers/CustomerDetails.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Detalles Cliente',
                breadcrumb: [
                    {
                        text: 'Detalles Cliente',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/usuarios',
            name: 'Usuarios',
            component: () => import('@/views/pages/Users/UsersList.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Usuarios',
                breadcrumb: [
                    {
                        text: 'Usuarios',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/register',
            name: 'Register',
            component: () => import('@/views/pages/authentication/Register.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/login',
            name: 'Login',
            component: () => import('@/views/pages/authentication/Login.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/logout',
            name: 'Logout',
            component: () => import('@/views/pages/authentication/Login.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('accessToken')
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (token) {
            next()
        } else {
            next({ name: 'Login' })
        }
    } else {
        next()
    }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
